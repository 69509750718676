<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <validar-fecha/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BCard, BCardText } from 'bootstrap-vue';
import ValidarFecha from '@/assets/componentes/profesionalCargar/validarFecha/ValidarFecha.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidarFecha,
},

}
</script>

<style>

</style>
