<template>
  <b-card-code
    title="Validar Fecha"
  >

    <!-- fila inputs fechas -->
    <b-row>
      <!-- columna "desde"se puede cambiar el ancho modificando el md -->
      <b-col
        cols="20"
        md="1"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label for="example-input">Desde:   </label>
      </b-col>
      <!-- columna imput fecha inicial -->
      <b-col
        cols="20"
        md="3"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">

        <b-input-group class="mb-1">
          <b-form-input
            id="example-input"
            v-model="fechaInicial"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            show-decade-nav
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="fechaInicial"
              show-decade-nav
              button-only
              button-variant="outline-primary"
              right
              size="sm"
              locale="en-US"
              aria-controls="example-input"
              @context="onContext"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <!-- columna "hasta" -->
      <b-col
        cols="20"
        md="1"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label for="example-input">Hasta</label>
      </b-col>
      <!-- columna input fecha final -->
      <b-col
        cols="20"
        md="3"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-input-group class="mb-1">
          <b-form-input
            id="example-input"
            v-model="fechaFinal"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            show-decade-nav
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="fechaFinal"
              show-decade-nav
              button-only
              button-variant="outline-primary"
              right
              size="sm"
              locale="en-US"
              aria-controls="example-input"
              @context="onContext"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col>
        <template #code>
          {{ codeButtonOnly }}
        </template>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="capturarFecha(fechaInicial, fechaFinal)"
        >
          Validar
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          target="_blank"
          href="https://wetransfer.com/"
          type="submit"
          variant="success"
          class="mr-1"
        >
          Wetransfer
        </b-button>
      </b-col>
    </b-row>
    <span
      v-if="validar.estado === true"
    >
      <cargar-link
        :data_fecha="validar"
      />
    </span>
    <!-- danger -->
    <b-alert
      v-if="validar.estado === false"
      variant="danger"
      show
    >
      <div class="alert-body">
        La fecha inicial no puede ser menor que la fecha final :)
      </div>
    </b-alert>
  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormDatepicker, BInputGroup, BInputGroupAppend, BFormInput, BButton, BRow, BCol,
  VBModal, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CargarLink from '@/assets/componentes/profesionalCargar/cargarLink/CargarLink.vue'
import { codeButtonOnly } from './code'

export default {
  components: {
    BCol,
    BRow,
    BCardCode,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BAlert,
    CargarLink,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      fechaInicial: '',
      fechaFinal: '',
      formatted: '',
      selected: '',
      fechasCapturadas: {},
      codeButtonOnly,
      validar: '',
    }
  },
  computed: {
  },

  methods: {

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    capturarFecha(fechaI, fechaF) {
      this.fechasCapturadas = {
        fecha_inicial: fechaI,
        fecha_final: fechaF,
      }
      this.$store.dispatch('apiFast/capturarFecha', this.fechasCapturadas).then(response => {
        // console.log(response.data, 'envioooooooooooooooooooooooooooooooo')
        this.validar = response.data
      })
    },
  },
}
</script>
