<template>
  <b-card-code
    no-body
  >
    <div>
      <small>
        {{ capturarFecha }}
      </small>
      <div class="d-flex left-content-between  flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Ordenar"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="3"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  ninguno
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filtro"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Escriba aquí "
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <p />
      </div>
      <b-table
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        class="mb-0"
        responsive
        striped
        @filtered="onFiltered"
      >
        <!-- columna documento -->
        <template #cell(documento)="row">
          <small>{{ row.item.documento }}</small>
        </template>

        <!-- columna nombre -->
        <template #cell(nombre)="row">
          <small>{{ row.item.nombre }}</small>
        </template>

        <!-- columna procedimiento -->
        <template #cell(procedimiento)="row">
          <small>{{ row.item.procedimiento }}</small>
        </template>

        <template #cell(prioritario)="row">
          <center>
            <small
              v-if="row.item.prioritario === 'no prioritario'"
            >NO  </small>
            <small
              v-else
            >SI </small>
          </center>
        </template>

        <template #cell(lectura)="row">
          <center>
            <small
              v-if="row.item.lectura === 'sin lectura'"
            >NO  </small>
            <small
              v-else
            >SI </small>
          </center>
        </template>

        <!-- columna codigo -->
        <template #cell(codigo)="row">
          <small>{{ row.item.codigo }}</small>
        </template>

        <template #cell(acciones)="row">

          <!-- boton editar -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal="`invoice-row-${row.item.codigo}`"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon
              icon="Edit3Icon"
              size="12"
            />
          </b-button>
          <!-- modal link-->
          <b-modal
            :id="`invoice-row-${row.item.codigo}`"
            cancel-variant="outline-secondary"
            ok-title="Cargar"
            ok-only
            title="Cargar Link"
            button-size="sm"
            centered
            @ok="onClick(row.item.codigo)"
          >
            <!-- formulario del modal de cargar link -->
            <b-form>
              <b-form-group
                label="link"
                label-for="dropdown-form-email"
              >
                <b-form-input
                  id="dropdown-form-email"
                  v-model="resultado"
                  size="sm"
                  placeholder="link"
                />
              </b-form-group>
              <b-form-group>
                <b-form-input
                  id="dropdown-form-password"
                  :value="row.item.codigo"
                  size="sm"
                  placeholder="Password"
                />
              </b-form-group>

            </b-form>
          </b-modal>

          <!-- columna codigo -->
        </template>
        <template #cell(status)="data">
          <b-button
            :id="`invoice-row-${data.item.codigo}`"
            target="_blank"
            :href="data.item.resultado"
            class="btn-icon rounded-circle"
            :variant="status[0][data.value]"
          >
            <feather-icon
              :icon="status[1][data.value]"
              size="20"
            />
          </b-button>
          <!-- tooltip cuando hay link de resultado -->
          <b-tooltip
            v-if="data.item.resultado.length > 0"
            :target="`invoice-row-${data.item.codigo}`"
            placement="top"
          >
            <p class="mb-0">
              Click para abrir el link
            </p>
            <p class="mb-0">
              {{ data.item.resultado }}
            </p>
          </b-tooltip>
          <!-- tooltip sin resultado -->
          <b-tooltip
            v-else
            :target="`invoice-row-${data.item.codigo}`"
            placement="top"
          >
            <p class="mb-0">
              No se han cargado resultados
            </p>
          </b-tooltip>
        </template>
      </b-table>
    </div>
    <p />
    <div>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          :small="small"
          label="Por Pag."
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
    <template #code>
      {{ codeRowDetailsSupport }}
    </template>

    <b-modal
      id="modal-enviar-mensaje"
      ok-only
      ok-title="Aceptar"
      centered
      title="Enviar Mensaje al usuario"
    >
      <b-card-text>¿Estás seguro de enviar el mensaje? <br> <br> Esto le avisará al paciente que su resultado ya está disponible para consultar</b-card-text>
    </b-modal>
    <template #codecopy>
      {{ codePrevent }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BTooltip, BButton, BPagination, BFormGroup, BCardBody, BFormSelect,
  VBModal, BFormInput, BForm, BModal, VBTooltip, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { codePrevent } from '@/assets/componentes/profesionalCargar/cargarLink/code copy'
import Ripple from 'vue-ripple-directive'
import { codeRowDetailsSupport } from './code'

export default {
  components: {
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BModal,
    BFormInput,
    BPagination,
    BFormGroup,
    BCardBody,
    BCardCode,
    BForm,
    BTable,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    data_fecha: Object,
  },
  data() {
    return {
      codePrevent,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      link: '',
      resultado: '',
      codigo: '',
      name: '',
      nameState: null,
      submittedNames: [],
      small: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      items: [],
      fields: [

        { key: 'documento', label: 'documento', sortable: true },
        { key: 'nombre', label: 'nombre' },
        { key: 'procedimiento', label: 'procedimiento', sortable: true },
        { key: 'prioritario', label: 'prioritario', sortable: true },
        { key: 'lectura', sortable: true },

        { key: 'codigo', label: 'codigo' },

        { key: 'status', label: 'Status' },
        'acciones',
      ],

      /* eslint-disable global-require */
      status: [{
        true: 'flat-success', false: 'flat-danger',
      },
      {
        true: 'CheckCircleIcon', false: 'XCircleIcon',
      }],
      codeRowDetailsSupport,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    // eslint-disable-next-line vue/return-in-computed-property
    capturarFecha() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dataFecha = {
        fecha_inicial: this.data_fecha.fecha_inicial,
        fecha_final: this.data_fecha.fecha_final,
        correo: this.$store.state.login.user.email
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      this.$store.dispatch('apiFast/dataProfesionalCargar', this.dataFecha).then(response => {
        // console.log(response.data)
        this.items = response.data
        this.totalRows = this.items.length
      })
    },
  },

  methods: {
    // eslint-disable-next-line camelcase
    onClick(codigo_id) {
      this.dataActualizar = {
        codigo: codigo_id,
        resultado: this.resultado,
      }
      // console.log(this.link, 'validar link')
      this.$store.dispatch('apiFast/actualizarPaciente', this.dataActualizar).then(response => response)
      this.resultado = ''

      this.dataFecha = {
        fecha_inicial: this.data_fecha.fecha_inicial,
        fecha_final: this.data_fecha.fecha_final,
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      this.$store.dispatch('apiFast/dataProfesionalCargar', this.dataFecha).then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
      this.dataTelefono = {
        codigo: codigo_id,
        texto: `codigo ${this.data_fecha.codigo}`,
      }
      this.$store.dispatch('apiFast/enviarMensaje', this.dataTelefono).then(response => response)
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    // comen
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}

</script>
